import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  passedDataToDialog,
  ResponseDataFromDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-organisation-deletion-request-view',
  templateUrl: './organisation-deletion-request-view.component.html',
  styleUrls: ['./organisation-deletion-request-view.component.scss']
})
export class OrganisationDeletionRequestViewComponent
  implements OnInit, OnDestroy
{
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  accessToken = '';
  errorButtonClass = ButtonClass.ERROR;
  myForm: FormGroup | undefined;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;

  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({
          organization: this.data.organisationData.orgId,
          newToken: true
        })
        .subscribe((token: string) => {
          this.accessToken = token;
        })
    );
  }

  onClose(accepted: boolean): void {
    this.showSpinner$.next(true);

    this.updateOrgDeletionRequest(accepted);
  }

  updateOrgDeletionRequest(accepted: boolean): void {
    if (this.data.organisationData.orgId) {
      const orgId = this.data.organisationData.orgId;
      const status = accepted ? 'accepted' : 'denied';

      const payload = {
        dealerSapRefId: this.data.organisationData.dealerId,
        status,
        auth0Id: this.data.organisationData.orgId
      };

      this.apiService
        .setOrganisationDeletionRequestStatus(orgId, payload, this.accessToken)
        .subscribe({
          next: () => {
            const text =
              status === 'accepted'
                ? this.translateService.instant(
                    'organization.delete_organization_request.accepted'
                  )
                : this.translateService.instant(
                    'organization.delete_organization_request.denied'
                  );

            this.displayPopup.emit({
              text,
              className: ''
            });

            const response: ResponseDataFromDialog = {
              action: 'deletion',
              updateStatus: status,
              refreshIsRequired: status !== 'denied'
            };

            this.showSpinner$.next(false);
            this.closeDialogWindow.emit(response);
          },
          error: () => {}
        });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
