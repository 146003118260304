import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import { Store } from '@ngrx/store';
import {
  getDealers,
  selectDealerByNameSearchData,
  selectDealerFiltersData,
  selectDefaultDealer,
  selectNearestDealerSearchData,
  selectNearestDealerSearchDataPending
} from '../shared/state/dealers/dealers.selectors';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OrganisationDealerModel } from '../shared/models/organisation-dealer.model';
import { OrganisationShopModel } from '../shared/models/organisation-shop.model';
import { environment } from 'src/environments/environment';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  DialogMode,
  IMarketingPermission,
  IOrganisationRegistrationDialogInput,
  IRegistrationOrganisationAddress,
  OrganisationRegistrationDialogComponent
} from 'cde-fe-organization-registration-dialog';
import { AuthService } from '../shared/services/auth.service';
import { OrganisationRegistrationDialogUserModel } from '../shared/models/organisationRegistrationDialogUser.model';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IGetDealerRequest,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import {
  getSearchAddressResult,
  selectAddressDetails,
  selectCurrentOrganisation,
  selectOrganisationError,
  selectRegisteredOrganisation,
  selectRegistrationDefaultOrganisation,
  getRegisterOrganisationPending
} from '../shared/state/organisations/organisations.selectors';
import {
  loadAddressDetails,
  loadRegistrationDefaultOrganisation,
  registerOrganisation,
  searchAddress,
  setCurrentOrganisation
} from '../shared/state/organisations';
import {
  selectAddressAutocompleteSuggestionsData,
  selectRegistrationDataPending,
  selectRegistrationDealerData,
  selectRegistrationErrorData,
  selectRegistrationPersonalData,
  selectRegistrationPersonalDataPending,
  selectRegistrationShopData
} from '../shared/state/global/global.selectors';
import {
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  updateRegistrationPersonalData
} from '../shared/state/global';
import {
  getDealer,
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  loadOrganisationDefaultDealer
} from '../shared/state/dealers';
import { UserOrganisationSummary } from '../shared/state/organisations/organisations.state';
import { ApiService } from '../shared/services/api.service';
import { AddressHelperService } from '../shared/helpers/address.helper.ts.service';
import {
  getClaasMarketingPermission,
  getDealerMarketingPermissions
} from '../shared/state/marketing-permission/marketing-permission.selectors';
import {
  createClaasMarketingPermission,
  createDealerMarketingPermission,
  getClaasMarketingPermission as getClaasMarketingPermissionFromBackend,
  getDealerMarketingPermission as getDealerMarketingPermissionFromBackend
} from '../shared/state/marketing-permission/marketing-permission.actions';
import { getFeatureToggles } from '../shared/state/feature-toggle/feature-toggle.selectors';
import { Features } from '../shared/models/feature-toggle.model';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export declare enum RegistrationShopName {
  ORGANIZATION = 'ORGANIZATION',
  USER_DATA = 'USER_DATA',
  DEALER = 'DEALER',
  SHOP = 'SHOP'
}

declare const window: any;

@Component({
  selector: 'app-organisation-data-dealers',
  templateUrl: './organisation-data-dealers.component.html',
  styleUrls: ['./organisation-data-dealers.component.scss']
})
export class OrganisationDataDealersComponent implements OnInit, OnDestroy {
  @Input() isAdmin = false;
  @Input() token = '';
  @Input() organisation: UserOrganisationSummary = {};
  @Input() currentUser: any = new OrganisationRegistrationDialogUserModel();
  @Input() organisationShops: OrganisationShopModel[] | null | undefined;
  @Input() registrationSteps: IRegistrationDialogStep[] | undefined | null;
  @Input() organisationConnectedDealers:
    | OrganisationDealerModel[]
    | null
    | undefined;

  @Output() disconnectDealers = new EventEmitter<
    OrganisationDealerModel[] | undefined | null
  >();

  @Output() setNewOrganisationDealer = new EventEmitter<any>();

  private subscriptions = new Subscription();
  private dialogSubscriptions: Subscription | null = null;
  private marketingPermissionFeatureToggle = false;

  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  organisationDefaultDealer: OrganisationDealerModel | undefined =
    new OrganisationDealerModel();
  isHandset = false;
  organisationDefaultDealerInfo$ = new BehaviorSubject(false);

  constructor(
    private authService: AuthService,
    private store: Store,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private addressHelper: AddressHelperService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store.select(selectDefaultDealer).subscribe(result => {
        if (result) {
          this.organisationDefaultDealer = result;

          this.organisationDefaultDealer = new OrganisationDealerModel({
            ...result,
            state: result.state,
            territory: result.territory,
            country: this.addressHelper.translateCountryValue(
              result.country.toLowerCase()
            )
          });

          this.organisationDefaultDealerInfo$.next(true);
        } else {
          this.store.dispatch(
            loadOrganisationDefaultDealer({
              orgaId: this.organisation['auth0Id'],
              dealerId: this.organisation['dealer'].dealerId,
              token: this.token
            })
          );
        }
      })
    );

    this.subscriptions.add(
      this.store
        .select(getFeatureToggles)
        .subscribe(
          featureToggles =>
            (this.marketingPermissionFeatureToggle =
              featureToggles?.some(
                toggle => toggle.id === Features.MARKETING_PERMISSION
              ) ?? false)
        )
    );
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  editShops(): void {
    if (environment.shops_url) {
      window.location.href =
        environment.shops_url + '?orgId=' + this.organisation['auth0Id'];
    } else {
      console.log('Error: missing link');
    }
  }

  openDialog(context: string): void {
    const data: passedDataToDialog = {
      context,
      connectedDealers: this.organisationConnectedDealers,
      largeDisplay: true,
      buttonSave: 'Save',
      buttonCancel: 'Cancel'
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          map((answer: ResponseDataFromDialog) => {
            if (answer.refreshIsRequired) {
              this.disconnectDealers.emit(answer.disconnectedDealers);
            }
          })
        )
        .subscribe()
    );
  }

  openDefaultDealerDialog(): void {
    const data = this.createDialogInput();

    const dialogRef = this.dialog.open(
      OrganisationRegistrationDialogComponent,
      {
        data: data
      }
    );

    this.processWithDialogRef(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(response => {
        this.dialogSubscriptions?.unsubscribe();
        if (response['dealerId']) {
          this.organisationDefaultDealer = undefined;
          this.organisationDefaultDealerInfo$.next(false);

          this.setNewOrganisationDealer.emit({
            org: this.organisation,
            dealerId: response['dealerId']
          });
        }
      });
  }

  processWithDialogRef(dialogRef: MatDialogRef<any>): void {
    this.dialogSubscriptions = new Subscription();
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealerFilter.subscribe(() =>
        this.store.dispatch(loadDealerFiltersData())
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getNearestDealers.subscribe((request: any) =>
        this.store.dispatch(loadNearestDealersSearchData(request))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getAddressAutocomplete.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(
            loadAddressAutocompleteSuggestionsData({
              addressDetailsRequest: request
            })
          )
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealersByName.subscribe((request: any) => {
        this.store.dispatch(loadDealerByNameSearchData(request));
      })
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.clearRegistrationData.subscribe(() =>
        this.store.dispatch(clearRegistrationData())
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationDefaultOrganisation.subscribe(
        (country: string) =>
          this.store.dispatch(loadRegistrationDefaultOrganisation({ country }))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationShopData.subscribe(
        (request: any) =>
          this.store.dispatch(loadRegistrationShopData({ country: request }))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationPersonalData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationPersonalData({
              country: request.country,
              language: request.language
            })
          );
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getRegistrationDealerData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationDealerData({
              language: request.language
            })
          );
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.setCurrentOrganisation.subscribe(
        (org: any) => {
          this.store.dispatch(
            setCurrentOrganisation({
              auth0Id: org.auth0Id ? org.auth0Id : ''
            })
          );
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.maintainPersonalData.subscribe(
        (request: any) =>
          this.store.dispatch(
            updateRegistrationPersonalData({
              request: request.request,
              language: request.language
            })
          )
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.registerOrganisationWithDealerAndShops.subscribe(
        (request: any) => this.store.dispatch(registerOrganisation(request))
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.stepperError.subscribe((message: string) => {
        const translatedMsg = this.translateService.instant(message);
        this.displayPopup(translatedMsg, 'error');
      })
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealerData.subscribe(
        (request: IGetDealerRequest) => {
          this.store.dispatch(getDealer({ request }));
        }
      )
    );

    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getAddressDetails.subscribe(
        (request: IAddressDetailsRequest) =>
          this.store.dispatch(
            loadAddressDetails({ addressDetailsRequest: request })
          )
      )
    );

    // searchAddress
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.searchAddress.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(searchAddress({ request }))
      )
    );

    // Marketing permissions
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getClaasMarketingPermission.subscribe(() =>
        this.store.dispatch(getClaasMarketingPermissionFromBackend())
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.getDealerMarketingPermission.subscribe(
        (request: IGetDealerRequest) =>
          this.store.dispatch(
            getDealerMarketingPermissionFromBackend({
              dealerId: request.dealerId,
              language: request.language
            })
          )
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.createClaasMarketingPermission.subscribe(
        (request: IMarketingPermission) =>
          this.store.dispatch(createClaasMarketingPermission({ request }))
      )
    );
    this.dialogSubscriptions.add(
      dialogRef.componentInstance.createDealerMarketingPermission.subscribe(
        (request: IMarketingPermission) =>
          this.store.dispatch(createDealerMarketingPermission({ request }))
      )
    );
  }

  getRegistrationOrgaAddress(address: any): IRegistrationOrganisationAddress {
    return {
      type: address.type ? address.type.toString() : '',
      houseNumber: address.houseNumber ? address.houseNumber.toString() : '',
      postalCode: address.postalCode ? address.postalCode.toString() : '',
      postbox: address.postbox ? address.postbox.toString() : '',
      street: address.street ? address.street.toString() : '',
      city: address.city ? address.city.toString() : '',
      suburb: address.suburb ? address.suburb : '',
      state: address.state ? address.state : '',
      territory: address.territory ? address.territory.toString() : '',
      addressAddition: address.addressAddition
        ? address.addressAddition.toString()
        : '',
      country: address.country ? address.country : '',
      latitude: address.latitude ? address.latitude : '',
      longitude: address.longitude ? address.longitude : ''
    };
  }

  // do not use extra class anymore to avoid inconsistencies between interface
  // and class when the version of the dialog changes
  private createDialogInput(
    input?: Partial<IOrganisationRegistrationDialogInput>
  ): Partial<IOrganisationRegistrationDialogInput> {
    return {
      user: this.currentUser,
      dialogSteps: this.registrationSteps ? this.registrationSteps : [],
      disableAutostart: false,
      dialogMode: DialogMode.DEALER_ONLY_SELECTION,
      orgAddress: this.getRegistrationOrgaAddress(
        this.organisation['addresses'][0]
          ? this.organisation['addresses'][0]
          : {}
      ),
      registrationDataPending$: this.store.select(
        selectRegistrationDataPending
      ),
      searchNearestDealerPending$: this.store.select(
        selectNearestDealerSearchDataPending
      ),
      currentDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      currentOrganisation$: this.store.select(selectCurrentOrganisation),
      registrationDealerData$: this.store.select(selectRegistrationDealerData),
      searchNearestDealers$: this.store.select(selectNearestDealerSearchData),
      dealerFilters$: this.store.select(selectDealerFiltersData),
      addressAutocompleteSuggestions$: this.store.select(
        selectAddressAutocompleteSuggestionsData
      ),
      addressDetails$: this.store.select(selectAddressDetails),
      searchDealersByName$: this.store.select(selectDealerByNameSearchData),
      registrationPersonalData$: this.store.select(
        selectRegistrationPersonalData
      ),
      registrationError$: this.store.select(selectRegistrationErrorData),
      organisationsError$: this.store.select(selectOrganisationError),
      getRegisteredOrganisation$: this.store.select(
        selectRegisteredOrganisation
      ),
      registrationDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      registrationPersonalDataPending$: this.store.select(
        selectRegistrationPersonalDataPending
      ),
      registrationShopData$: this.store.select(selectRegistrationShopData),
      dealersData$: this.store.select(getDealers),
      addressSearchResult$: this.store.select(getSearchAddressResult),
      bearerToken: this.token,
      apiBaseUrl: environment.base_urls.cc_3_api_base_url,
      clientId: environment.auth.azure_maps_client_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UC_UI: window.UC_UI,
      claasMarketingPermission$: this.store.select(getClaasMarketingPermission),
      dealerMarketingPermissions$: this.store.select(
        getDealerMarketingPermissions
      ),
      marketingPermissionFeatureToggle: this.marketingPermissionFeatureToggle,
      registerOrganisationPending$: this.store.select(
        getRegisterOrganisationPending
      ),
      ...(input ?? {})
    };
  }

  private displayPopup(text: string, className?: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
      panelClass: className ? className : ''
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
