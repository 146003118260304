import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ApiService } from '../shared/services/api.service';
import { ICouplingConsentTexts } from '../shared/models/couplings';
import {
  ButtonClass,
  ButtonSize,
  DropdownOptionObject
} from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './org-selection.component.html',
  styleUrls: ['./org-selection.component.scss']
})
export class OrgSelectionComponent implements OnInit, OnDestroy {
  selectedOrganization: any;
  params = new URLSearchParams();
  login_domain = environment.auth.auth_domain;
  orgSelection = true;
  showConsent = false;
  consentGiven = false;
  clientId: string | undefined;
  scope = '';
  consentsTexts: any[] = [];
  couplingScopes: string[] = [];
  showSpinner = true;
  largeSize = CircularProgressDiameter.LARGE;
  color = ProgressColor.PRIMARY;
  primaryButtonClass = ButtonClass.PRIMARY;
  secondaryButtonClass = ButtonClass.SECONDARY_OUTLINE;
  buttonSize = ButtonSize.LARGE;
  organizations = [];
  organizationOptions = [
    {
      text: 'label',
      key: 'key'
    }
  ];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(res => {
        const token = res['session_token'];
        const decoded: any = jwt_decode(token);
        const language = decoded['language'];
        this.translate.use(language);
        const country = decoded['country'];
        this.scope = decoded['query']['scope'];

        if (decoded['query']['orgSelection']) {
          this.organizations = decoded['organizations'];
          this.organizationOptions = this.setOrgOptions();
          this.selectedOrganization = this.organizations[0];
          const queryParameters = decoded['query'];
          delete queryParameters['orgSelection'];

          if (queryParameters['client_id'] && queryParameters['coupling']) {
            this.clientId = queryParameters['client_id'];
            this.showConsent = true;
            this.couplingScopes =
              decoded['scope'] && decoded['scope'].length
                ? decoded['scope'].split(' ')
                : [];
          }

          for (let key in queryParameters) {
            this.params.set(key, queryParameters[key]);
          }
        } else {
          this.orgSelection = false;
          const queryParameters = decoded['query'];
          this.selectedOrganization = decoded['organization'];
          for (let key in queryParameters) {
            this.params.set(key, queryParameters[key]);
          }
          this.params.set('organization', this.selectedOrganization);
          window.open(
            `https://${this.login_domain}/authorize?${this.params.toString()}`,
            '_self'
          );
        }
        if (this.showConsent) {
          this.getConsentText(language, this.couplingScopes, country);
        }
      })
    );
  }

  setOrgOptions() {
    var options: any[] = [];
    for (var ot of this.organizations) {
      var o = { text: ot['name'], key: ot['auth0Id'] };
      options.push(o);
    }
    return options;
  }

  getDefaultOrgOption() {
    return {
      text: this.selectedOrganization['name'],
      key: this.selectedOrganization['auth0Id']
    };
  }

  selectOrg(o: DropdownOptionObject): void {
    this.selectedOrganization = this.organizations.find(
      org => org['auth0Id'] == (o as unknown as string)
    );
    console.log(this.selectedOrganization);
  }

  sendOrg(consent: string) {
    this.params.set('organization', this.selectedOrganization['auth0Id']);
    // Set this parameter to get the org also in the current namespace in access token
    this.params.set('organizationId', this.selectedOrganization['uuid']);
    this.params.set('consentGiven', consent);
    this.params.set('scope', this.scope);
    console.log(this.params.get('consentGiven'));
    window.open(
      `https://${this.login_domain}/authorize?${this.params.toString()}`,
      '_self'
    );
  }

  getConsentText(language: string, scopes: string[], country: string) {
    const consentsObs: Observable<any>[] = [];

    // make a call to the Api for each scope
    scopes.forEach(scope =>
      consentsObs.push(
        this.apiService.getCouplingConsentText(language, scope, country)
      )
    );

    this.subscriptions.add(
      forkJoin(consentsObs)
        .pipe(
          map(
            (results: any[]) => {
              results.forEach((res: any, index: number) => {
                const currentConsentText: any = {};

                // replace all {{country}} placeholders by the actual country
                const regex = /{{\w+}}/g;
                res.texts = res.texts.map((item: any) => {
                  item.value = item.value.replace(regex, country);
                  return item;
                });

                // build a dictionary for the current consent
                res.texts.forEach((item: any) => {
                  currentConsentText[item.key] = item.value;
                });

                this.consentsTexts[index] = currentConsentText;
              });

              this.showSpinner = false;
            },
            (err: any) => {
              const errorString = JSON.stringify(err);
              console.log(errorString);
            }
          )
        )
        .subscribe()
    );
  }

  cancel() {
    window.open(environment.login_urls.cc_login_url, '_self');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
