import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import {
  loadMarketingPermissions,
  loadUserCoordinates,
  marketingPermissionsLoaded,
  marketingPermissionsNotLoaded,
  userCoordinatesLoaded
} from './user-coordinates.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class UserCoordinatesEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  loadUserCoordinatesData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserCoordinates),
      switchMap(() =>
        this.apiService
          .getUserProfile()
          .pipe(
            map(userItem => userCoordinatesLoaded({ userDetails: userItem }))
          )
      )
    );
  });

  loadMarketingPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMarketingPermissions),
      switchMap(action =>
        this.authService.getTokenSilently$().pipe(
          switchMap(token =>
            this.apiService.getMarketingPermissions(action.claasId, token).pipe(
              map(permissions => marketingPermissionsLoaded({ permissions })),
              catchError(error => of(marketingPermissionsNotLoaded({ error })))
            )
          )
        )
      )
    );
  });
}
