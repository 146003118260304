export class DealerConnectionRequest {
  orgId: string;
  customerSAPId: string;
  dealerSAPId: string;
  connectionAccepted: boolean | null;

  constructor() {
    this.orgId = '';
    this.customerSAPId = '';
    this.dealerSAPId = '';
    this.connectionAccepted = false;
  }
}
