<div class="dealer-connection-request-view">
  <div class="dealer-connection-request-view__header">
    <div class="dealer-connection-request-view__header-main">
      <h2 class="dealer-connection-request-view__header-title subtitle-2">
        {{ 'dealerConnections.header' | translate }}
      </h2>
    </div>
  </div>

  <div *ngIf="this.dealerName; else loadingContainer" class="dealer-connection-request-view__body">
    <p class="dealer-connection-request-view__body-text">{{ 'dealerConnections.connectionRequestText' | translate : { dealerName: this.dealerName} }}</p>
    <p class="dealer-connection-request-view__body-text" >{{ 'dealerConnections.confirmConnection' | translate }}</p>
  </div>

  <ng-template #loadingContainer>
    <claas-circular-progress
      [color]="secondaryColor"
      [diameter]="largeSize"></claas-circular-progress>
  </ng-template>

  <div class="dealer-connection-request-view-buttons">
    <claas-button
      *ngIf="data?.buttonCancel"
      [buttonClass]="secondaryTextButtonClass"
      [text]="'generic.deny' | translate"
      (onClick)="onClose(false)"
      class="btn-left">
    </claas-button>

    <claas-button
      *ngIf="data?.buttonSave"
      [buttonClass]="primaryButtonClass"
      [text]="'generic.confirm' | translate"
      (onClick)="onClose(true)"
      class="btn-right" />
  </div>
</div>
