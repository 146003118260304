export const environment = {
  applications: {
    applications: ['CLAAS_connect'],
    shop_applications: [
      'Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
      'WDgoUQHGSSsU2lvb3cTcUTMw9HHwRIrW',
      'QvskCmBK3byUppUhVf8V6c5pD8AMFMwv',
      'N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
      'iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
      '5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
      'okvxNon88NESWOpU1LmZqthbtCRyIFOL',
      '7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
      '6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
      'DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
      'jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
      'YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
      'cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
      'HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
      'fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
      'RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
      'eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf'
    ]
  },
  auth: {
    auth_audience: 'https://user-api.accounts.f67a9d15.pincubator.tech',
    auth_client_id: 'He6Dwzxre2FqrpXvay0S27tHl3xHmhLQ',
    auth_domain: 'login.f67a9d15.pincubator.tech',
    auth_token_key: 'api_auth_token',
    azure_maps_client_id: '316d2088-cf93-41fa-83e6-62d0ece21ab5',
    unauthed_routes: [
      '/org-selection',
      '/info-page',
      '/submit',
      '/complete',
      '/shop-page',
      '/callback',
      '/verified',
      '/info',
      '/privacy'
    ]
  },
  base_urls: {
    api_base_url: 'https://api.accounts.f67a9d15.pincubator.tech',
    app_base_url: 'https://accounts.f67a9d15.pincubator.tech',
    cc_3_base_url: 'https://93c13274.pincubator.tech/',
    cc_3_api_base_url: 'https://api.93c13274.pincubator.tech',
    cc_app_base_url: 'com.claas.store.connect://',
    cc_3_app_base_url: 'com.claas.store.connect://',
    cc_base_url: 'https://staging.0fa9ad50.pincubator.tech',
    cid_base_url: 'https://accounts.f67a9d15.pincubator.tech',
    collection_base_url: 'https://www.claas-online.com:445/fanshop',
    et_base_url_ar: 'https://www.claas-online.com:445/etshop_ar',
    et_base_url_at: 'https://www.claas-online.com:445/etshop_at',
    et_base_url_au: 'https://www.claas-online.com:445/etshop_au',
    et_base_url_ch: 'https://www.claas-online.com:445/etshop_ch',
    et_base_url_cz: 'https://www.claas-online.com:445/etshop_cz',
    et_base_url_dk: 'https://www.claas-online.com:445/etshop_dk',
    et_base_url_ee: 'https://www.claas-online.com:445/etshop_ee',
    et_base_url_fr: 'https://www.claas-online.com:445/etshop_fr',
    et_base_url_lt: 'https://www.claas-online.com:445/etshop_lt',
    et_base_url_lv: 'https://www.claas-online.com:445/etshop_lv',
    et_base_url_nl: 'https://www.claas-online.com:445/etshop_nl',
    et_base_url_nz: 'https://www.claas-online.com:445/etshop_nz',
    et_base_url_pl: 'https://www.claas-online.com:445/etshop_pl',
    et_base_url_ro: 'https://www.claas-online.com:445/etshop_ro',
    et_base_url_sk: 'https://www.claas-online.com:445/etshop_sk',
    et_base_url_uk: 'https://www.claas-online.com:445/etshop_uk',
    et_base_url: 'https://www.claas-online.com:445/etshop',
    fcr_base_url: 'https://projekty.mda.pl/fcr/',
    fcu_base_url: 'https://www.claas-online.com:445/fcu',
    ls_base_url: 'https://www.claas-online.com:445/smp',
    mp_doi_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech/marketing-doi',
    mps_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech/marketing-permission',
    partsdoc_base_url: 'https://partsdoc-mobile-registered.claas.com',
    registration_service_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech', // /v3/registrations',
    storage_base_url: 'https://cliddevi18n.blob.core.windows.net/translations/',
    user_api_base_url: 'https://user-api.accounts.f67a9d15.pincubator.tech',
    claas_connect_farm:
      'https://sb-farm.93c13274.pincubator.tech/login/dashboard',
    claas_connect_farm_app:
      'https://sb-farm.93c13274.pincubator.tech/login/dashboard'
  },
  client_ids: {
    auth_collection_id: 'qr8r5cD263n9UHKORpGUOUTEidWUy0zv',
    auth_connect_3_id: 'br2wkbbm8Ujw2xD9o8CZPI080MVjWr7w',
    auth_connect_3_app_id: 'n340sGRSUmfMwQhNs5B1KOHbGWo07za8',
    auth_et_id_ar: 'eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf',
    auth_et_id_at: '6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
    auth_et_id_au: 'vbtuqtAOvHHJbD4XNnl8b8lYTCFtcr9f',
    auth_et_id_ch: 'jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
    auth_et_id_cz: 'cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
    auth_et_id_dk: 'DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
    auth_et_id_ee: 'fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
    auth_et_id_fr: 'iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
    auth_et_id_lt: 'HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
    auth_et_id_lv: 'RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
    auth_et_id_nl: '5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
    auth_et_id_nz: 'IyaYhYpTbFN0hEb4G64NmZ8uQj3t9CKx',
    auth_et_id_pl: 'okvxNon88NESWOpU1LmZqthbtCRyIFOL',
    auth_et_id_ro: '7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
    auth_et_id_sk: 'YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
    auth_et_id_uk: 'N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
    auth_et_id: 'Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
    auth_fcr_id: 'SWJbZhbdfjSqhpeml0F6A767AZICwi1x',
    auth_fcu_id: 'SWIQHftWXdNPsHYsjwAZ6Qi1FyBcLrO6',
    auth_iwc_client_id: 'UGdntbvhOaWdFAvW3vysNXiK5EE5HlSH',
    auth_ls_id: 'qoNaDjBPnojevdR0jviUD7ZRDdSINr9c',
    auth_partsdoc_id: 'QvskCmBK3byUppUhVf8V6c5pD8AMFMwv',
    claas_connect_farm: '1VLdTg6YGQrtemCXI1ojIlm3W2WD1n9f',
    claas_connect_farm_app: 'ETVvmmsGTzrd2WJvFBZeDKv4U7J3p5fy'
  },
  general: {
    address_types: ['home'],
    countries: [
      'ar',
      'at',
      'au',
      'ca',
      'ch',
      'cz',
      'be',
      'de',
      'ee',
      'es',
      'dk',
      'fi',
      'fr',
      'gb',
      'hr',
      'it',
      'ie',
      'nl',
      'lu',
      'lv',
      'lt',
      'no',
      'nz',
      'ro',
      'pl',
      'pt',
      'py',
      'se',
      'si',
      'sk',
      'ua',
      'us',
      'uy',
      'hu',
      'kz',
      'za'
    ],
    countriesDetails: [
      {
        id: 'ar',
        label: 'registration.formfield.select.country.argentina',
        img: '/assets/images/flags/de.svg',
        code: '+54',
        iso: 'arg'
      },
      {
        id: 'at',
        label: 'registration.formfield.select.country.austria',
        img: '/assets/images/flags/de.svg',
        code: '+43',
        iso: 'aut'
      },
      {
        id: 'au',
        label: 'registration.formfield.select.country.australia',
        img: '/assets/images/flags/de.svg',
        code: '+61',
        iso: 'aus'
      },
      {
        id: 'be',
        label: 'registration.formfield.select.country.belgium',
        img: '/assets/images/flags/be.svg',
        code: '+32',
        iso: 'bel'
      },
      {
        id: 'ch',
        label: 'registration.formfield.select.country.switzerland',
        img: '/assets/images/flags/ch.svg',
        code: '+41',
        iso: 'che'
      },
      {
        id: 'ca',
        label: 'registration.formfield.select.country.canada',
        img: '/assets/images/flags/ca.svg',
        code: '+1',
        iso: 'can'
      },
      {
        id: 'cz',
        label: 'registration.formfield.select.country.czech',
        img: '/assets/images/flags/cz.svg',
        code: '+420',
        iso: 'cze'
      },
      {
        id: 'de',
        label: 'registration.formfield.select.country.germany',
        img: '/assets/images/flags/de.svg',
        code: '+49',
        iso: 'deu'
      },
      {
        id: 'dk',
        label: 'registration.formfield.select.country.denmark',
        img: '/assets/images/flags/dk.svg',
        code: '+45',
        iso: 'dnk'
      },
      {
        id: 'es',
        label: 'registration.formfield.select.country.spain',
        img: '/assets/images/flags/pl.svg',
        code: '+34',
        iso: 'esp'
      },
      {
        id: 'ee',
        label: 'registration.formfield.select.country.estonia',
        img: '/assets/images/flags/et.svg',
        code: '+372',
        iso: 'est'
      },
      {
        id: 'gb',
        label: 'registration.formfield.select.country.unitedkingdom',
        img: '/assets/images/flags/no.svg',
        code: '+44',
        iso: 'gbr'
      },
      {
        id: 'hr',
        label: 'registration.formfield.select.country.croatia',
        img: '/assets/images/flags/hr.svg',
        code: '+385',
        iso: 'hrv'
      },
      {
        id: 'lt',
        label: 'registration.formfield.select.country.lithuania',
        img: '/assets/images/flags/lt.svg',
        code: '+370',
        iso: 'ltu'
      },
      {
        id: 'lv',
        label: 'registration.formfield.select.country.latvia',
        img: '/assets/images/flags/lv.svg',
        code: '+371',
        iso: 'lva'
      },
      {
        id: 'lu',
        label: 'registration.formfield.select.country.luxembourg',
        img: '/assets/images/flags/lu.svg',
        code: '+352',
        iso: 'lux'
      },
      {
        id: 'fi',
        label: 'registration.formfield.select.country.finland',
        img: '/assets/images/flags/fr.svg',
        code: '+358',
        iso: 'fin'
      },
      {
        id: 'fr',
        label: 'registration.formfield.select.country.france',
        img: '/assets/images/flags/fr.svg',
        code: '+33',
        iso: 'fra'
      },
      {
        id: 'ie',
        label: 'registration.formfield.select.country.ireland',
        img: '/assets/images/flags/ie.svg',
        code: '+353',
        iso: 'irl'
      },
      {
        id: 'it',
        label: 'registration.formfield.select.country.italy',
        img: '/assets/images/flags/fr.svg',
        code: '+39',
        iso: 'ita'
      },
      {
        id: 'nl',
        label: 'registration.formfield.select.country.netherlands',
        img: '/assets/images/flags/nl.svg',
        code: '+31',
        iso: 'nld'
      },
      {
        id: 'no',
        label: 'registration.formfield.select.country.norway',
        img: '/assets/images/flags/no.svg',
        code: '+47',
        iso: 'nor'
      },
      {
        id: 'nz',
        label: 'registration.formfield.select.country.newzealand',
        img: '/assets/images/flags/nz.svg',
        code: '+64',
        iso: 'nzl'
      },
      {
        id: 'pl',
        label: 'registration.formfield.select.country.poland',
        img: '/assets/images/flags/pl.svg',
        code: '+48',
        iso: 'pol'
      },
      {
        id: 'pt',
        label: 'registration.formfield.select.country.portugal',
        img: '/assets/images/flags/pl.svg',
        code: '+351',
        iso: 'prt'
      },
      {
        id: 'py',
        label: 'registration.formfield.select.country.paraguay',
        img: '/assets/images/flags/py.svg',
        code: '+595',
        iso: 'pry'
      },
      {
        id: 'ro',
        label: 'registration.formfield.select.country.romania',
        img: '/assets/images/flags/ro.svg',
        code: '+40',
        iso: 'rou'
      },
      {
        id: 'se',
        label: 'registration.formfield.select.country.sweden',
        img: '/assets/images/flags/se.svg',
        code: '+46',
        iso: 'swe'
      },
      {
        id: 'si',
        label: 'registration.formfield.select.country.slovenia',
        img: '/assets/images/flags/si.svg',
        code: '+386',
        iso: 'svn'
      },
      {
        id: 'sk',
        label: 'registration.formfield.select.country.slovakia',
        img: '/assets/images/flags/sk.svg',
        code: '+421',
        iso: 'svk'
      },
      {
        id: 'ua',
        label: 'registration.formfield.select.country.ukraine',
        img: '/assets/images/flags/ua.svg',
        code: '+380',
        iso: 'ukr'
      },
      {
        id: 'us',
        label: 'registration.formfield.select.country.usa',
        img: '/assets/images/flags/us.svg',
        code: '+1',
        iso: 'usa'
      },
      {
        id: 'uy',
        label: 'registration.formfield.select.country.uruguay',
        img: '/assets/images/flags/uy.svg',
        code: '+598',
        iso: 'ury'
      },
      {
        id: 'hu',
        label: 'registration.formfield.select.country.hungary',
        img: '/assets/images/flags/hu.svg',
        code: '+36',
        iso: 'hun'
      },
      {
        id: 'kz',
        label: 'registration.formfield.select.country.kazakhstan',
        img: '/assets/images/flags/kz.svg',
        code: '+7',
        iso: 'kaz'
      },
      {
        id: 'za',
        label: 'registration.formfield.select.country.southafrica',
        img: '/assets/images/flags/za.svg',
        code: '+27',
        iso: 'zaf'
      }
    ],
    email_pattern:
      "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+[.]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+",
    languages: [
      'cs',
      'da',
      'de',
      'es',
      'et',
      'en-gb',
      'en-us',
      'fi',
      'fr',
      'hr',
      'it',
      'lv',
      'lt',
      'nl',
      'no',
      'ro',
      'pl',
      'pt',
      'sl',
      'sk',
      'sv',
      'uk',
      'hu',
      'ru'
    ],
    name_length: 2,
    name_pattern: '[ÄÖÜÙÚÀÝÉÈäöüùúèéáàßA-Za-z -]*',
    password_length: 8,
    password_pattern: "[A-Za-z0-9]*[!#$%&'*+/=?^_`{|}~-]+[A-Za-z0-9]*",
    phone_pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s.\0-9]*$',
    phone_types: ['phone', 'mobile'],
    salutations: ['mr', 'mrs'],
    unitSystems: [
      { value: 'METRIC' },
      { value: 'IMPERIAL' },
      { value: 'CUSTOMARY' }
    ]
  },
  login_urls: {
    cc_login_url: 'https://93c13274.pincubator.tech?login=true',
    collection_login_url:
      'https://login.f67a9d15.pincubator.tech/samlp/UNKPDIK4wgWFvcC42XCBXHQ6sG9PASSq',
    et_login_url_ar:
      'https://login.f67a9d15.pincubator.tech/samlp/eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf',
    et_login_url_at:
      'https://login.f67a9d15.pincubator.tech/samlp/6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
    et_login_url_au:
      'https://login.b389c9ef.pincubator.tech/samlp/vbtuqtAOvHHJbD4XNnl8b8lYTCFtcr9f',
    et_login_url_ch:
      'https://login.f67a9d15.pincubator.tech/samlp/jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
    et_login_url_cz:
      'https://login.f67a9d15.pincubator.tech/samlp/cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
    et_login_url_dk:
      'https://login.f67a9d15.pincubator.tech/samlp/DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
    et_login_url_ee:
      'https://login.f67a9d15.pincubator.tech/samlp/fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
    et_login_url_fr:
      'https://login.f67a9d15.pincubator.tech/samlp/iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
    et_login_url_lt:
      'https://login.f67a9d15.pincubator.tech/samlp/HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
    et_login_url_lv:
      'https://login.f67a9d15.pincubator.tech/samlp/RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
    et_login_url_nl:
      'https://login.f67a9d15.pincubator.tech/samlp/5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
    et_login_url_nz:
      'https://login.b389c9ef.pincubator.tech/samlp/IyaYhYpTbFN0hEb4G64NmZ8uQj3t9CKx',
    et_login_url_pl:
      'https://login.f67a9d15.pincubator.tech/samlp/okvxNon88NESWOpU1LmZqthbtCRyIFOL',
    et_login_url_ro:
      'https://login.f67a9d15.pincubator.tech/samlp/7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
    et_login_url_sk:
      'https://login.f67a9d15.pincubator.tech/samlp/YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
    et_login_url_uk:
      'https://login.f67a9d15.pincubator.tech/samlp/N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
    et_login_url:
      'https://login.f67a9d15.pincubator.tech/samlp/Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
    fcu_login_url:
      'https://login.gcp01.claas-labs.de/samlp/SWIQHftWXdNPsHYsjwAZ6Qi1FyBcLrO6',
    ls_login_url:
      'https://login.gcp01.claas-labs.de/samlp/qoNaDjBPnojevdR0jviUD7ZRDdSINr9c',
    partsdoc_login_url: 'https://partsdoc-mobile-registered.claas.com/'
  },
  marketing_footnotes: {
    at: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, die zuvor genannten jeweils Mühlenwinkel 1, 3328 Harsewinkel, Deutschland, E-Mail: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Österreich, E-Mail: info-at@claas.com (hiernach auch „CLAAS“), 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Deutschland;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Deutschland',
    hr: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, gore spomenuti Mühlenwinkel 1, 3328 Harsewinkel, Njemačka, e-pošta: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Austrija, e-pošta: info-at@claas.com (u daljnjem tekstu također „CLAAS”); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Njemačka;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Njemačka',
    si: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, na naslovu Mühlenwinkel 1, 3328 Harsewinkel, Nemčija, e-pošta: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Avstrija, e-pošta: info-at@claas.com (v nadaljevanju tudi »CLAAS«); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Nemčija;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Nemčija',
    ca: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, CLAAS of America, Inc., 8401 South 132nd Street, Omaha, NE 68138, USA (hereinafter "CLAAS sales company")',
    de: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, jeweils Mühlenwinkel 1, 33428 Harsewinkel, Deutschland, CLAAS Vertriebsgesellschaft mbH, Benzstraße 5, 33442 Herzebrock-Clarholz, Deutschland (hiernach „CLAAS Vertriebsgesellschaft“), 365FarmNet GmbH, Hausvogteiplatz 10, 10117 Berlin, Deutschland',
    es: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, respectivamente Mühlenwinkel 1, 33428 Harsewinkel, Alemania, Claas Ibérica, S.A.U., C/ Zeus 5, 28880 Meco (Madrid), Espana, (en lo sucesivo, «sociedad comercializadora de CLAAS»)',
    fr: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, sises Mühlenwinkel 1, 33428 Harsewinkel, Allemagne, CLAAS France, Chemin des Grands Prés du Gué, 28320 Ymeray, France, (ci-après dénommées « société de distribution CLAAS ») et 365FarmNet GmbH, Hausvogteiplatz 10, 10117 Berlin, Allemagne',
    gb: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, CLAAS UK Ltd., Saxham, Bury St. Edmunds, Suffolk, IP28 6QZ, United Kingdom (hereinafter “CLAAS sales company”)',
    it: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, rispettivamente Mühlenwinkel 1, 33428 Harsewinkel, Germania, CLAAS Italy S.p.A., Via Torino 9, 13100 Vercelli VC, Italy (di seguito “Società di vendita CLAAS”)',
    pl: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, adres Mühlenwinkel 1, 33428 Harsewinkel, Niemcy, E-Mail: infoclaas@claas.com; CLAAS Polska Sp.z.o.o. , ul. Świerkowa 7 , Niepruszewo,64-320 Buk, Polska, E-Mail: claas.polska@claas.com (w dalszej części jako „spółka handlowa CLAAS”); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Niemcy; CLAAS Financial Services SAS SA Spółka Akcyjna Uproszczona Oddział w Polsce, ul. Grzybowska 78, 00-844 Warszawa, Polska; CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Niemcy',
    us: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, E-Mail: infoclaas@claas.com, CLAAS of America, Inc., 8401 South 132nd Street, Omaha, NE 68138, USA, E-Mail: info@claas.com (hereinafter “CLAAS Salescompany”)'
  },
  production: false,
  name: 'development',
  shops_url: 'https://www.93c13274.pincubator.tech/shops',
  info_url: 'https://help.connect.claas.com/'
};
