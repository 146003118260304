import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.belongToFilteredClaasApiCalls(req)) {
      // internal claasId calls
      if (this.belongsToExceptionalApiCalls(req)) {
        // the request has already an authorization header
        req = req.clone();
        return next.handle(req);
      } else {
        // the request needs an authorization header
        return this.auth.getAccessTokenSilently().pipe(
          mergeMap(token => {
            const tokenReq = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
            });
            return next.handle(tokenReq);
          }),
          catchError(error =>
            throwError(() => {
              console.log(error);
              return new Error(error);
              // Do not clone/handle here, because otherwise it will lead to
              // duplicated backend calls when the request in line 37 fails
              // req = req.clone({ setHeaders: {} });
              // return next.handle(req);
            })
          )
        );
      }
    } else {
      // external claasId calls
      req = req.clone({ setHeaders: {} });
      return next.handle(req);
    }
  }

  belongToFilteredClaasApiCalls(req: HttpRequest<any>): boolean {
    return (
      (req.url.includes(environment.base_urls.api_base_url) ||
        req.url.includes(environment.base_urls.user_api_base_url) ||
        req.url.includes(environment.base_urls.cc_3_api_base_url) ||
        req.url.includes(environment.base_urls.mps_base_url)) &&
      !req.url.startsWith(
        environment.base_urls.user_api_base_url + '/v3/users/send_email'
      ) &&
      !req.url.startsWith(
        'https://' + environment.auth.auth_domain + '/continue?state='
      ) &&
      !(
        this.router.url.includes('type=doi') ||
        this.router.url.includes('type=new') ||
        this.router.url.includes('type=update') ||
        this.router.url.includes('type=consent') ||
        this.router.url.includes('org-selection') ||
        this.router.url.includes('info?')
      )
    );
  }

  belongsToExceptionalApiCalls(req: HttpRequest<any>): boolean {
    return (
      (req.url.includes('/v3/organizations/') &&
        !req.url.includes('/v3/organizations/validate') &&
        !req.url.includes('/v3/organizations/summary') &&
        !req.url.includes('/v3/organizations/idp-users/') &&
        !req.url.includes('/v3/organizations/members/')) ||
      (req.url.includes('/v3/user') && req.method === 'DELETE') ||
      req.url.includes('/v3/social-user') ||
      req.url.includes('/v3/profile-picture') ||
      (req.url.includes('/permission') && !req.url.includes('/permission/')) ||
      (req.url.includes('/invitation') &&
        !req.url.includes('/invitation/') &&
        !req.url.includes('/marketing-permission/') &&
        !req.url.includes('/invitation?uuids=') &&
        !req.url.includes('/v3/organizations/members/'))
    );
  }
}
