<div class="organisation-deletion-request-view">
  <div class="organisation-deletion-request-view__header">
    <div class="organisation-deletion-request-view__header-main">
      <h2 class="organisation-deletion-request-view__header-title subtitle-2">
        {{ 'deletion.org_deletion.header' | translate }}
      </h2>
    </div>
  </div>

  <div class="organisation-deletion-request-view__body">
    <div class="organisation-deletion-request-view__body-content">
      <div class="organisation-deletion-request-view__body-content--line">
        {{ 'deletion.org_deletion.text' | translate: { orgName: data.organisationData.orgName } }}
      </div>

      <div class="organisation-deletion-request-view__body-content--line">
        <span class="label subtitle-2">
          <p>{{ 'deletion.sap_dealer_author' | translate }} : </p>
        </span>

        <p> {{ data.organisationData.dealerName }}</p>
      </div>

      <div class="organisation-deletion-request-view__body-content--line">
        <span class="label subtitle-2">
          <p>{{ 'deletion.organisation_name' | translate }} : </p>
        </span>

        <p> {{ data.organisationData.orgName }}</p>
      </div>
    </div>
  </div>

  <div class="organisation-deletion-request-view__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
        <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
          <span class="flex">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div></div>
    </div>

    <div class="right-side">
      <claas-button
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.deny' | translate"
        (onClick)="onClose(false)"
        class="btn-left">
      </claas-button>

      <claas-button
        *ngIf="data?.buttonSave"
        [buttonClass]="errorButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.confirm' | translate"
        (onClick)="onClose(true)"
        class="btn-right" />
    </div>
  </div>
</div>
