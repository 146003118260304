import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  passedDataToDialog,
  ResponseDataFromDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { DealerResponse } from '../../../preference-center/preference-center.component';
import { map } from 'rxjs/operators';
import { DealerConnectionRequest } from '../../models/dealer-connection-request.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dealer-connection-request-view',
  templateUrl: './dealer-connection-request-view.component.html',
  styleUrls: ['./dealer-connection-request-view.component.scss']
})
export class DealerConnectionRequestViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  primaryButtonClass = ButtonClass.PRIMARY;
  myForm: FormGroup | undefined;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;

  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  accessToken: string = '';
  dealerName: string = '';
  connectionRequest: DealerConnectionRequest = new DealerConnectionRequest();

  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const dealerSapRefId = this.data?.dealerConnectionRequest?.dealerSAPId;

    if (dealerSapRefId) {
      this.subscriptions.add(
        this.authService
          .getTokenSilently$({
            organization: this.data.dealerConnectionRequest?.orgId,
            newToken: true
          })
          .pipe(
            switchMap(token => {
              this.accessToken = token;
              return this.getDealerName(dealerSapRefId);
            })
          )
          .subscribe((name: string) => {
            this.dealerName = name;
          })
      );
    }
  }

  onClose(accepted: boolean): void {
    this.updateDealerConnectionRequest(accepted);
  }

  updateDealerConnectionRequest(accepted: boolean): void {
    if (this.data?.dealerConnectionRequest) {
      this.connectionRequest.connectionAccepted = accepted;
      this.connectionRequest.dealerSAPId =
        this.data.dealerConnectionRequest?.dealerSAPId;
      this.connectionRequest.customerSAPId =
        this.data.dealerConnectionRequest?.customerSAPId;
      this.connectionRequest.orgId =
        this.data.dealerConnectionRequest?.orgId;

      this.subscriptions.add(
        this.apiService
          .setDealerConnectionRequest(this.connectionRequest, this.accessToken)
          .subscribe({
            next: () => {
              this.closeDialogWindow.emit();
            },
            error: () => {
              console.log('error setting status');
              this.closeDialogWindow.emit();
            }
          })
      );
    }
  }

  private getDealerName(dealerId: string): Observable<string> {
    return this.apiService
      .getPreferredDealer(dealerId)
      .pipe(
        map((res: DealerResponse) => {
          return res?.dealerName || '';
        })
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
