import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import { mergeMap, of, switchMap } from 'rxjs';
import {
  dealerByNameSearchDataLoaded,
  dealerByNameSearchDataNotLoaded,
  dealerFiltersDataLoaded,
  getDealer,
  getDealerComplete,
  getDealerFailure,
  getDealerSuccess,
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  loadOrganisationDefaultDealer,
  nearestDealersSearchDataLoaded,
  nearestDealersSearchDataNotLoaded,
  organisationDefaultDealerLoaded
} from './dealers.actions';
import { catchError, finalize, map } from 'rxjs/operators';
import {
  IDealerData,
  IDealerFilterResponse
} from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';
import { State } from '..';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class DealersEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private apiService: ApiService,
    private store: Store<State>
  ) {}

  loadOrganisationDefaultDealerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganisationDefaultDealer),
      switchMap(action =>
        this.apiService
          .getDealerDetails(action.orgaId, action.dealerId, action.token)
          .pipe(
            map((defaultDealer: any) =>
              organisationDefaultDealerLoaded({ defaultDealer })
            )
          )
      )
    );
  });

  loadNearestDealersSearchData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadNearestDealersSearchData),
      mergeMap(action => {
        return this.apiService.getRegistrationNearestDealers(action).pipe(
          map(result =>
            nearestDealersSearchDataLoaded({
              nearestDealersSearchData: result
            })
          ),
          catchError(error =>
            of(
              nearestDealersSearchDataNotLoaded({
                nearestDealersSearchData: error.payload,
                organisationError: error.payload
              })
            )
          )
        );
      })
    );
  });

  loadDealerByNameSearchData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDealerByNameSearchData),
      mergeMap(action => {
        return this.apiService.getRegistrationDealerByName(action).pipe(
          map((result: any) =>
            dealerByNameSearchDataLoaded({ dealerByNameSearchData: result })
          ),
          catchError((error: any) =>
            of(
              dealerByNameSearchDataNotLoaded({
                organisationError: error.payload
              })
            )
          )
        );
      })
    );
  });

  loadDealerFiltersData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDealerFiltersData),
      mergeMap(() =>
        this.apiService.getDealerFilters().pipe(
          map((result: IDealerFilterResponse) =>
            dealerFiltersDataLoaded({ dealerFilters: result.filters })
          ),
          catchError(() => of(dealerFiltersDataLoaded({ dealerFilters: null })))
        )
      )
    );
  });

  getDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDealer),
      map(action => action.request),
      mergeMap(({ dealerId, language }) =>
        this.apiService.getDealer(dealerId, language).pipe(
          map((dealer: IDealerData) => getDealerSuccess({ dealer })),
          catchError(error => of(getDealerFailure({ dealerId, error }))),
          finalize(() => {
            this.store.dispatch(getDealerComplete());
          })
        )
      )
    )
  );
}
