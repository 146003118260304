<div class='org-selection'>
  <div class='org-selection-panel' *ngIf="orgSelection">
    <h1 class='org-selection-headline'>
      {{ 'orgSelection.org_headline' | translate }}
    </h1>
    <div *ngIf="organizations?.length; else noOrgsFound">
      <claas-dropdown-key-value
        [defaultOption]="getDefaultOrgOption()"
        [label]="'orgSelection.organization' | translate"
        [options]="organizationOptions"
        (onChange)="
          selectOrg($event)
      ">
      </claas-dropdown-key-value>
      <!-- Consent -->
      <div *ngIf="showConsent && consentsTexts" style="margin-bottom: 50px;">
        <claas-circular-progress
          *ngIf="showSpinner"
          [color]="color"
          [diameter]="largeSize"></claas-circular-progress>
        <div *ngFor="let texts of consentsTexts">
          <h2 class="consent-header">{{ texts["headline"] }}</h2>
          <p> {{ texts["prefix_text_0"] }}</p>
          <p [innerHTML]="texts['prefix_text_1']"></p>
          <ul class="data-types">
            <li>{{ texts["data_types_operational_data"] }}</li>
            <li>{{ texts["data_types_machine_identification"] }}</li>
            <li>{{ texts["data_types_machine_master_data"] }}</li>
            <li>{{ texts["data_types_machine_performance_data"] }}</li>
            <li>{{ texts["data_types_machine_movement_data"] }}</li>
            <li>{{ texts["data_types_environmental_data"] }}</li>
          </ul>
          <p>
            {{ texts["service_request_text"] }}
          </p>
          <ul class="data-types">
            <li>{{ texts["service_request_data_personal_identification_data"] }}</li>
            <li>{{ texts["service_request_data_contact_data"] }}</li>
          </ul>
          <p [innerHTML]="texts['data_privacy_notice']"></p>
          <br>
          <p>{{ texts["international_data_transfer"] }}</p>
          <br>
          <p>{{ texts["consent_revocation_notice"] }}</p>
        </div>
    </div>
    <div class="org-selection-actions">
      <claas-button
        *ngIf="showConsent"
        class="org-selection-button"
        [text]="'generic.cancel' | translate"
        [buttonClass]="secondaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          cancel()
        "></claas-button>
      <claas-button
        *ngIf="!showConsent"
        class="org-selection-button"
        [text]="'orgSelection.submit_button' | translate"
        [buttonClass]="primaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          sendOrg('false')
        "></claas-button>
      <claas-button
        *ngIf="showConsent"
        class="org-selection-button"
        [text]="consentsTexts[0]['button_confirm']"
        [buttonClass]="primaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          sendOrg('true')
        "></claas-button>
    </div>

    </div>
  </div>

  <div class='org-selection-panel' *ngIf="!orgSelection">
    <claas-circular-progress
    [color]="color"
    [diameter]="largeSize"></claas-circular-progress>
  </div>
</div>

<ng-template #noOrgsFound>
  {{ 'orgSelection.no_orgs' | translate }}
</ng-template>
